<template>
  <!--Dynamically render component-->
  <keep-alive>
    <component
      :is="selectedComponent"
      :transaction="transaction"
      @passModalClose="$emit('passModalClose', $event)"
      @passComponent="selectedComponent = `${$event}Wrapper`"
      @passBack="selectedComponent = $event"
      :selectedComponent="selectedComponent"
    ></component>
  </keep-alive>
</template>
<script>
export default {
  name: "AllocationStep",
  props: ["transaction", "step_count"],
  components: {
    AllocationRadioGroup: () => import("./AllocationRadioGroup.vue"),
  },
  data() {
    return {
      selectedComponent: "AllocationRadioGroup",
    };
  },
  created() {
    // Dynamically imports and adds all relevant child components
    // To use more components just add their name to the array below
    const supportedComponents = [
      "GeneralJournalEntries",
      "InterAccountTransfers",
      "CustomerPayments",
      "CreditNoteRefunds",
    ];
    supportedComponents.forEach((name) => {
      this.$options.components[`${name}Wrapper`] = () =>
        import("./" + name + "/" + name + "Wrapper" + ".vue");
    });
  },
};
</script>